<template>
  <div style="margin: 20px">
    <div class="flex justify-between items-center">
      <p class="font-bold text-lg">Student List</p>
    </div>
    <div class="flex flex-wrap mt-5 gap-5">
      <div
        class="student-item flex items-center"
        v-for="(item, index) in studentList"
        :key="index"
      >
        <img
          class="student-avatar"
          :src="
            item.student_info.avatar_url ||
            'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png'
          "
        />
        <div class="flex-1">
          {{
            item.student_info.student_lastName +
            item.student_info.student_givenName
          }}
        </div>
        <div class="view-btn" @click="goDetail(item)">View</div>
      </div>
    </div>
  </div>
</template>

<script>
import { getApplicationsByAdvisor } from "../../api/eae";
import { getAccountId } from "../../utils/store";
export default {
  data() {
    return {
      studentList: [],
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      getApplicationsByAdvisor({ account_id: getAccountId()})
        .then((res) => {
          if (res.data.code === 0) {
            let result = res.data.data
            let map = new Map()
            for(let i=0;i<result.length;i++){
              let app = result[i]
              let student_id = app.student_info._id.$id
              if(!map.has(student_id)){
                map.set(student_id,app)
              }
            }
            this.studentList =  map.values()
          }
        })
        .finally(() => loading.close());
    },
    goDetail(item) {
      this.$router.push({
        path: "/home/studentDetail",
        query: {
          id: item.student_info._id.$id,
        },
      });
    },
  },
};
</script>

<style lang="scss">
.student-item {
  width: calc(50% - 10px);
  background-color: white;
  padding: 18px 24px;
  .student-avatar {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin-right: 18px;
  }
  .view-btn {
    padding: 6px 26px;
    border-radius: 5px;
    cursor: pointer;
    background: #579eff;
    color: white;
    font-size: 15px;
    font-weight: bold;
    display: flex;
    align-items: center;
  }
}
</style>
